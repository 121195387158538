import React from "react";

const EllipsisText = ({ text, size = 20 }) => {
  if (!text) return null;

  const isTruncated = text.length > size;
  const displayText = isTruncated ? text.slice(0, size) + "…" : text;

  return (
    <span className="ellipsis-text" data-full-text={text}>
      {displayText}
    </span>
  );
};

export default EllipsisText;
