import React, { useState, useEffect, useMemo, useRef } from 'react';
import './scam.scss';
import PhoneNumberInput from '../common/PhoneNumberInput';
import TextInput from '../common/TextInput';
import Button from '../common/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllScamArticles, reportScammer } from '../../js/redux/actions';
import {
  acceptOnlyNumbersInput,
  clearFormFieldErrors,
  isValidEmail,
  isValidPhoneNumber,
  isValidUpiId,
} from '../../js/utils';
import ErrorText from '../common/ErrorText';
import { toast } from 'react-toastify';
import FileUploadIcon from '../../images/icons/upload.svg';
import AudioFileIcon from '../../images/icons/Audio.svg';
import VideoFileIcon from '../../images/icons/Video.svg';
import ImageFileIcon from '../../images/icons/Image.svg';
import PdfFileIcon from '../../images/icons/pdf.svg';
import CloseBtnIcon from '../../images/icons/close_btn.svg';
import WarningIcon from '../../images/icons/warning.svg';
import Dropdown from '../common/Dropdown';
import SearchableDropdown from '../common/SearchableDropdown';
import { SELECT_TYPE_OPTIONS } from '../../js/constants';
import Loader from '../common/Loader';
import BlogPostCard from '../common/BlogPostCard';
import useFetchWithControl from '../hooks/useFetchWithControl';

const Report = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const initialFormData = {
    proof: '',
    type: location?.state?.type,
    phoneNo: '',
    upi: '',
    description: '',
    sms: '',
    email: '',
    website: '',
  };

  const [form, setForm] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [disabledField, setDisabledField] = useState(false);
  const [dropDownDisabled, setDropDownDisabled] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: allScamArticles = [],
    lastFetched = null,
    isFetching = false,
  } = useSelector((state) => ({
    data: state.allScamArticles?.data?.data,
    lastFetched: state.allScamArticles?.lastFetched,
    isFetching: state.allScamArticles?.isFetching,
  }));
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedScamArticle, setSelectedScamArticle] = useState(null);

  const shouldFetch = useMemo(() => {
    if (!allScamArticles?.length) return true;
    return !lastFetched || Date.now() - new Date(lastFetched).getTime() > 60000;
  }, [allScamArticles, lastFetched]);

  useFetchWithControl({
    fetchFn: () => dispatch(getAllScamArticles()),
    shouldFetch,
    interval: 60000,
  });

  const articleTitleOptions = useMemo(() => {
    if (!allScamArticles || isFetching) return [];

    return allScamArticles
      .slice()
      .sort((a, b) => (b.trendingScore ?? 0) - (a.trendingScore ?? 0))
      .map(({ slug, title }) => ({ key: slug, value: title }));
  }, [allScamArticles, isFetching]);

  useEffect(() => {
    const { type, uniqueId } = location?.state || {};

    // Default to 'number' type if no type or uniqueId is provided
    if (!type && !uniqueId) {
      setForm((prevForm) => ({ ...prevForm, type: 'number' }));
      setSelectedOption(SELECT_TYPE_OPTIONS[0]);
      return;
    }

    // Disable dropdown and set the field-specific disable state
    setDropDownDisabled(true);
    setDisabledField(type !== 'sms' && type !== 'email');

    const updatedForm = {
      type,
      phoneNo: '',
      upi: '',
      description: '',
      email: '',
      website: '',
    };

    // Map the type to the correct form field and selected option index
    const fieldMap = {
      number: 'phoneNo',
      upi: 'upi',
      sms: 'description',
      email: 'description',
      website: 'website',
    };

    const selectedOptionIndex =
      {
        number: 0,
        upi: 1,
        sms: 2,
        email: 3,
        website: 4,
      }[type] || 0;

    updatedForm[fieldMap[type]] = uniqueId || '';

    // Update form state and selected option
    setForm((prevForm) => ({ ...prevForm, ...updatedForm }));
    setSelectedOption(SELECT_TYPE_OPTIONS[selectedOptionIndex]);
  }, [location?.state]);

  const handlePhoneNoChange = (value) => {
    if (value === '' || acceptOnlyNumbersInput(value)) {
      setErrors(clearFormFieldErrors('phoneNo', errors));
      setForm((prevForm) => ({ ...prevForm, phoneNo: value }));
    }
  };

  const handleChange = (key, value) => {
    setForm((prevForm) => ({ ...prevForm, [key]: value }));
    setErrors((prevErr) => ({ ...prevErr, [key]: '' }));
  };

  const handleSelectedOption = (val) => {
    setSelectedOption(val);
    setForm({
      ...form,
      type: val.key,
    });
  };

  const handleArticleTitleOption = (val) => {
    setSelectedArticle(val);
    setSelectedScamArticle(
      allScamArticles.filter((item) => item.slug === val.key),
    );
    setForm({
      ...form,
      articleSlug: val.key,
    });
  };

  const getFileTypeIcon = () => {
    if (!form.proof) return;
    const fileType = form?.proof?.type.split('/');
    let fileTypeIcon = FileUploadIcon;
    if (fileType[0] === 'image') {
      fileTypeIcon = ImageFileIcon;
    } else if (fileType[0] === 'audio') {
      fileTypeIcon = AudioFileIcon;
    } else if (fileType[0] === 'video') {
      fileTypeIcon = VideoFileIcon;
    } else if (fileType[0] === 'application' && fileType[1] === 'pdf') {
      fileTypeIcon = PdfFileIcon;
    }
    return fileTypeIcon;
  };

  const validateFile = (file) => {
    const imageTypes = ['image/jpeg', 'image/png'];
    const videoTypes = ['video/mp4', 'video/quicktime']; // MP4, MOV
    const audioTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg']; // MP3, WAV, OGG
    const documentTypes = ['application/pdf'];

    const maxSize = 6 * 1024 * 1024; // 6MB for non-video files
    const maxVideoSize = 12 * 1024 * 1024; // 12MB for video files

    if (
      ![...imageTypes, ...videoTypes, ...audioTypes, ...documentTypes].includes(
        file.type,
      )
    ) {
      return 'Invalid file type. Please upload an image, video, audio, or PDF file.';
    }

    if (videoTypes.includes(file.type) && file.size > maxVideoSize) {
      return 'Video file size exceeds the 12MB limit.';
    }

    if (!videoTypes.includes(file.type) && file.size > maxSize) {
      return 'File size exceeds the 6MB limit.';
    }

    return null;
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (!uploadedFile) return;

    // Remove spaces from the filename
    const sanitizedFileName = uploadedFile.name.replace(/\s+/g, '_');
    const renamedFile = new File([uploadedFile], sanitizedFileName, {
      type: uploadedFile.type,
      lastModified: uploadedFile.lastModified,
    });

    const validationError = validateFile(renamedFile);

    setForm((prev) => ({
      ...prev,
      proof: validationError ? '' : renamedFile,
    }));

    setErrors((prev) => ({
      ...prev,
      proof: validationError || '',
    }));
  };

  const validateSaveData = () => {
    const err = { ...errors };

    const fieldValidations = {
      number: [
        {
          field: 'phoneNo',
          validate: isValidPhoneNumber,
          errorMessage: 'Enter a valid phone number',
        },
      ],
      upi: [
        {
          field: 'upi',
          validate: isValidUpiId,
          errorMessage: 'Enter a valid UPI Id',
        },
      ],
      sms: [
        {
          field: 'phoneNo',
          validate: (val) => !!val,
          errorMessage: 'This field is required',
        },
        {
          field: 'description',
          validate: (val) => !!val,
          errorMessage: 'This field is required',
        },
      ],
      email: [
        {
          field: 'email',
          validate: isValidEmail,
          errorMessage: 'Enter a valid e-mail Id',
        },
      ],
      website: [
        {
          field: 'website',
          validate: (val) => !!val,
          errorMessage: 'This field is required',
        },
      ],
    };

    // Validate form fields based on type
    const validations = fieldValidations[form.type] || [];

    const hasErrors = validations.some(({ field, validate, errorMessage }) => {
      if (!form[field] || (validate && !validate(form[field]))) {
        err[field] = errorMessage;
        return true;
      }
      return false;
    });

    // Validate 'proof' and 'description'
    if (!form.proof && !form.description) {
      err.description = 'This field is required';
      return setErrors(err) && false;
    }

    // If any validation fails, return false
    if (hasErrors) {
      setErrors(err);
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form data
    if (!validateSaveData()) return;

    setBtnDisable(true);
    setIsLoading(true);

    // Map form type to the corresponding uniqueId field
    const fieldMap = {
      number: form.phoneNo,
      upi: form.upi,
      sms: form.phoneNo,
      email: form.email,
      website: form.website,
    };

    const uniqueId = fieldMap[form.type];

    const postObj = {
      uniqueId,
      type: form.type,
      detail: form.description,
      articleSlug: form.articleSlug,
    };

    const postData = new FormData();
    postData.append('proof', form.proof);
    postData.append('json', JSON.stringify(postObj));

    // Dispatch the report action
    dispatch(reportScammer(postData))
      .then((resp) => {
        setIsLoading(false);

        if (resp.data.success) {
          toast.success(resp.data.message, { position: 'top-left' });
          navigate(`/`);
        } else {
          setTimeout(() => setBtnDisable(false), 3500);
          toast.error(resp.data.message, { position: 'top-left' });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setBtnDisable(false);
        toast.error('Something went wrong. Please try again later.', {
          position: 'top-left',
        });
      });
  };

  const labels = {
    sms: { label: 'SMS Content', placeholder: 'Copy Paste SMS Content' },
    email: { label: 'Email Content', placeholder: 'Copy Paste Email Content' },
  };

  const { label: descriptionLabel, placeholder: descriptionPlaceholder } =
    labels[form?.type] || {
      label: 'Description',
      placeholder: 'Provide Details',
    };

  return (
    <div
      className="fab-scam-reg-card-container"
      id="fab-scam-reg-card-container"
    >
      {isLoading && <Loader parentNode="fab-scam-reg-card-container" />}
      <div className="fab-scam-reg-card">
        <div className="fab-scam-reg-card-top-section">
          <div className="fab-page-heading blue-color">Report Scam</div>
          <img
            src={CloseBtnIcon}
            alt="close-btn"
            className="fab-cursor-pointer"
            onClick={() => navigate('/home')}
          />
        </div>
        <div className="fab-scam-reg-card-warning">
          <img src={WarningIcon} alt="warning-icon" />
          <div className="fab-scam-reg-card-warning-text">
            Make sure the information you provide is accurate and not intended
            to harm anyone or as a joke.
          </div>
        </div>
        <div className="fab-form-control">
          <Dropdown
            label="Select Type"
            placeholder="Select Type"
            options={SELECT_TYPE_OPTIONS}
            selectedValue={selectedOption}
            onChange={(val) => handleSelectedOption(val)}
            disabled={dropDownDisabled}
            height={50}
          />
        </div>

        {form?.type === 'number' && (
          <div className="fab-form-control">
            <div className="fab-form-label">Phone number of scammer</div>
            <PhoneNumberInput
              placeholder="01234 56789"
              value={form.phoneNo}
              name="phoneNo"
              onChange={(e) => handlePhoneNoChange(e.target.value)}
              disabled={disabledField}
            />
            <ErrorText error={errors.phoneNo} errorClass="w3-center" />
          </div>
        )}
        {form?.type === 'upi' && (
          <div className="fab-form-control">
            <div className="fab-form-label">UPI id of scammer</div>
            <TextInput
              inputclass="fab-upi-input-text"
              placeholder="Enter UPI id (ex.1234567890@upi)"
              value={form.upi}
              name="upi"
              onChange={(val) => handleChange('upi', val)}
              disabled={disabledField}
            />
            <ErrorText error={errors.upi} errorClass="w3-center" />
          </div>
        )}
        {form?.type === 'sms' && (
          <div className="fab-form-control">
            <div className="fab-form-label">Phone number</div>
            <PhoneNumberInput
              placeholder="01234 56789"
              value={form.phoneNo}
              name="phoneNo"
              onChange={(e) => handlePhoneNoChange(e.target.value)}
              disabled={disabledField}
            />
            <ErrorText error={errors.phoneNo} errorClass="w3-center" />
          </div>
        )}
        {form?.type === 'email' && (
          <div className="fab-form-control">
            <div className="fab-form-label">From Email id</div>
            <TextInput
              inputclass="fab-upi-input-text"
              placeholder="Sender Email"
              value={form.email}
              name="email"
              onChange={(val) => handleChange('email', val)}
              disabled={disabledField}
            />
            <ErrorText error={errors.email} errorClass="w3-center" />
          </div>
        )}
        {form?.type === 'website' && (
          <div className="fab-form-control">
            <div className="fab-form-label"> URL of Scam Website</div>
            <TextInput
              inputclass="fab-upi-input-text"
              placeholder="Enter the URL (ex.http://scammer.url.com)"
              value={form.website}
              name="website"
              onChange={(val) => handleChange('website', val)}
              disabled={disabledField}
            />
            <ErrorText error={errors.website} errorClass="w3-center" />
          </div>
        )}
        <div className="fab-form-control">
          <div className="fab-form-label">{descriptionLabel}</div>
          <div className="fab-textarea-container fab-input-container">
            <textarea
              className={`fab-input ${
                !form.description ? 'fab-input-placeholder' : 'fab-input-active'
              }`}
              name="description"
              placeholder={descriptionPlaceholder}
              value={form.description}
              onChange={(e) => handleChange('description', e.target.value)}
            />
          </div>
          <ErrorText error={errors.description} errorClass="w3-center" />
        </div>

        {/* Add the new Dropdown for Articles */}
        <div className="fab-form-control">
          <SearchableDropdown
            label="What Scam is this ?"
            placeholder="Select a matching Article"
            options={articleTitleOptions}
            selectedValue={selectedArticle}
            onChange={(val) => handleArticleTitleOption(val)}
            height={50}
          />
        </div>

        {selectedScamArticle?.length > 0 && (
          <BlogPostCard
            key={selectedScamArticle[0].slug}
            item={selectedScamArticle[0]}
          />
        )}

        {/* File upload and submit button */}
        <div className="fab-form-control">
          <div className="fab-form-label">Choose File </div>
          <label className="fab-file-card-container">
            {form.proof ? (
              <div className="fab-file-card-selected-file">
                <img src={getFileTypeIcon()} alt="file-type-pic" />
                <div className="fab-file-card-selected-file-name">
                  {form.proof?.name}
                </div>
              </div>
            ) : (
              <div className="fab-file-card-choose-file">
                <div className="fab-file-card-choose-file-text-section">
                  <img src={FileUploadIcon} alt="file-upload-icon" />
                  <div className="fab-file-card-sub-text-main">Select File</div>
                </div>
                <div className="fab-file-card-sub-text">
                  (Format: Images / Screenshots, Video / Screen Recordings,
                  Audio Recordings & PDF files)
                </div>
              </div>
            )}
            <input
              multiple
              name="proof"
              type="file"
              onChange={(e) => handleFileUpload(e)}
              accept="audio/*,video/*,image/*,.pdf"
            />
          </label>
          <ErrorText error={errors.proof} errorClass="w3-center" />
        </div>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          buttontext="Submit"
          disabled={btnDisable}
          customclass={`primary-btn ${btnDisable ? 'fab-btn-disabled' : ''}`}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

export default Report;
