import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useAuth from '../../../Routes/useAuth';
import {
  logout,
  onLogoutClearStoreData,
  setToastMessage,
} from '../../../js/redux/actions';
import './navbar.scss';
import ScamyodhaLogo from '../../../images/icons/scamyodha_app_icon.svg';
import UserProfileIcon from '../../../images/icons/user_default.svg';
import DownArrowIcon from '../../../images/icons/dropdown_down.svg';

// Helper function for handling navigation and closing drawer
const navigateAndCloseDrawer = (navigate, path, setOrToggleDrawer) => {
  navigate(path);
  setOrToggleDrawer(false);
};

const Header = ({ setOrToggleDrawer, showDrawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const {
    auth: { isAuthenticated },
    setAuthData,
    userData,
    loading,
  } = useAuth();

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownVisible((prevState) => !prevState);
  };

  const handleOutsideClick = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  }, []);

  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isDropdownVisible, handleOutsideClick]);

  const handleLogout = async () => {
    const resp = await dispatch(logout());
    if (resp?.data?.success) {
      dispatch(
        setToastMessage({ status: 'success', message: resp?.data?.message }),
      );
      setIsDropdownVisible(false);
      dispatch(onLogoutClearStoreData());
      setAuthData();
      setOrToggleDrawer(false);
      navigate(`/auth/login`);
    }
  };

  const userMenu = isAuthenticated && userData && (
    <div ref={dropdownRef} className="w3-hide-small">
      <div className="navbar-user-info" onClick={toggleDropdown}>
        <img src={UserProfileIcon} alt="User" className="navbar-user-icon" />
        <div className="navbar-user-name">{userData.name}</div>
        <img src={DownArrowIcon} alt="User" className="navbar-down-icon" />
      </div>
      {isDropdownVisible && (
        <div className="navbar-user-details w3-card w3-round w3-padding">
          <div className="navbar-user-phone">
            {`${userData.countryCode} ${userData.phoneNo}`}
          </div>
          <button
            className="logout-btn w3-bar-item w3-button"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );

  const authButtons = !isAuthenticated && !loading && (
    <div className="navbar-auth-buttons w3-hide-small">
      <button
        className="navbar-login-btn"
        onClick={() => navigate(`/auth/login`)}
      >
        Login
      </button>
      <button
        className="navbar-register-btn"
        onClick={() => navigate(`/auth/register`)}
      >
        Register
      </button>
    </div>
  );

  const mobileDrawerMenu = (
    <ul className="navbar-drawer-menu">
      {!isAuthenticated ? (
        <>
          <li className="navbar-drawer-item">
            <button
              className="navbar-mob-btn"
              onClick={() =>
                navigateAndCloseDrawer(
                  navigate,
                  `/auth/login`,
                  setOrToggleDrawer,
                )
              }
            >
              Login
            </button>
          </li>
          <li className="navbar-drawer-item">
            <button
              className="navbar-mob-btn"
              onClick={() =>
                navigateAndCloseDrawer(
                  navigate,
                  `/auth/register`,
                  setOrToggleDrawer,
                )
              }
            >
              Register
            </button>
          </li>
          <li className="navbar-drawer-item">
            <button
              className="navbar-mob-btn"
              onClick={() =>
                navigateAndCloseDrawer(navigate, `/learn`, setOrToggleDrawer)
              }
            >
              Learn
            </button>
          </li>
          <li className="navbar-drawer-item">
            <button
              className="navbar-mob-btn"
              onClick={() =>
                navigateAndCloseDrawer(
                  navigate,
                  `/leader-board`,
                  setOrToggleDrawer,
                )
              }
            >
              Leaderboard
            </button>
          </li>
        </>
      ) : (
        <>
          {['/home', '/learn', '/report-scam', '/reports', '/leader-board'].map(
            (path, index) => (
              <li key={index} className="navbar-drawer-item">
                <button
                  className="navbar-mob-btn"
                  onClick={() =>
                    navigateAndCloseDrawer(navigate, path, setOrToggleDrawer)
                  }
                >
                  {path
                    .split('/')
                    .pop()
                    .replace('-', ' ')
                    .replace(/^./, (char) => char.toUpperCase())}
                </button>
              </li>
            ),
          )}
        </>
      )}
    </ul>
  );

  const mobileDrawer = (
    <div className={`navbar-drawer ${showDrawer ? 'open' : ''}`}>
      <div className="navbar-drawer-close" onClick={setOrToggleDrawer}>
        &times;
      </div>
      {isAuthenticated && userData && (
        <div className="drawer-user-info">
          <img src={UserProfileIcon} alt="User" className="drawer-user-icon" />
          <div className="drawer-user-details">
            <div className="drawer-user-name">{userData.name}</div>
            <div className="drawer-user-phone">
              {`${userData.countryCode} ${userData.phoneNo}`}
            </div>
          </div>
        </div>
      )}
      {mobileDrawerMenu}
      {isAuthenticated && userData && (
        <button className="navbar-logout-btn" onClick={handleLogout}>
          Logout
        </button>
      )}
    </div>
  );

  return (
    <nav id="app-header" className="navbar-container">
      <div className="navbar-logo">
        <Link to="/" className="navbar-brand">
          <img
            src={ScamyodhaLogo}
            alt="Scam Yodha"
            className="navbar-logo-img"
          />
          <span className="navbar-title">SCAM YODHA</span>
        </Link>
      </div>
      <div
        className="navbar-hamburger w3-hide-large w3-hide-medium"
        onClick={setOrToggleDrawer}
      >
        &#9776;
      </div>
      {userMenu}
      {authButtons}
      {mobileDrawer}
    </nav>
  );
};

export default Header;
