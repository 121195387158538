/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import ScamyodhaLogo from '../../../images/icons/scamyodha_app_text_logo.svg';
import { CONTACT_MAIL, SOCIAL_LINKS } from '../../../js/constants';
import twitterLogo from '../../../images/icons/twitter_icon.svg';
import fbLogo from '../../../images/icons/facebook_icon.svg';
import linkedInLogo from '../../../images/icons/linkedin_icon.svg';
import discordLogo from '../../../images/icons/discord_blue.svg';
import playStore from '../../../images/icons/Play.svg';
import appStore from '../../../images/icons/apple.svg';
import './footer.scss';

const Footer = () => {
  const year = new Date();
  return (
    <footer id="app-footer" className="w3-container w3-padding-32">
      <div className="w3-row-padding w3-center">
        {/* Company Links */}
        <div className="w3-col s6 m2">
          <h4>Company</h4>
          <Link to="/about" className="w3-hover-text-blue">
            About Us
          </Link>
          <br />
          <Link to="/privacy-policy" className="w3-hover-text-blue">
            Privacy Policy
          </Link>
          <br />
          <Link to="/terms-of-use" className="w3-hover-text-blue">
            Terms of Use
          </Link>
        </div>

        {/* Support Links */}
        <div className="w3-col s6 m2">
          <h4>Support</h4>
          <a href={`mailto:${CONTACT_MAIL}`} className="w3-hover-text-blue">
            Contact Us
          </a>
          <br />
          <Link to="/faq" className="w3-hover-text-blue">
            FAQs
          </Link>
        </div>

        {/* App Download */}
        <div className="w3-col s12 m4 w3-margin-bottom">
          <h4>Get the App</h4>
          <div className="fab-footer-apps w3-center">
            <a href={SOCIAL_LINKS.playStore} target="_blank" rel="noreferrer">
              <img
                src={playStore}
                alt="Download on Play Store"
                className="w3-image fab-image-custom"
                style={{ maxWidth: '120px' }}
              />
            </a>
            <div className="fab-app-coming-soon w3-display-container">
              <img
                src={appStore}
                alt="App Store Coming Soon"
                className="w3-image w3-opacity"
                style={{ maxWidth: '120px' }}
              />
              <p className="w3-small w3-display-bottommiddle">Coming Soon!</p>
            </div>
          </div>
        </div>

        {/* Branding Section */}
        <div className="w3-col m4">
          <img
            src={ScamyodhaLogo}
            alt="Scamyodha Logo"
            className="w3-image"
            style={{ maxWidth: '150px' }}
          />
          <div className="w3-row-padding w3-center w3-margin-top">
            <a
              href={SOCIAL_LINKS.discordChannel}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={discordLogo}
                alt="Discord"
                className="w3-image w3-circle"
                style={{ width: '40px', margin: '0 10px' }}
              />
            </a>
            <a href={SOCIAL_LINKS.facebook} target="_blank" rel="noreferrer">
              <img
                src={fbLogo}
                alt="Facebook"
                className="w3-image w3-circle"
                style={{ width: '40px', margin: '0 10px' }}
              />
            </a>
            <a href={SOCIAL_LINKS.linkedIn} target="_blank" rel="noreferrer">
              <img
                src={linkedInLogo}
                alt="LinkedIn"
                className="w3-image w3-circle"
                style={{ width: '40px', margin: '0 10px' }}
              />
            </a>
            <a href={SOCIAL_LINKS.twitter} target="_blank" rel="noreferrer">
              <img
                src={twitterLogo}
                alt="Twitter"
                className="w3-image w3-circle"
                style={{ width: '40px', margin: '0 10px' }}
              />
            </a>
          </div>
          <p className="w3-small">
            Your trusted app for fraud detection and prevention.
          </p>
        </div>
      </div>

      {/* Footer Rights */}
      <div className="w3-row-padding w3-center w3-margin-top w3-small">
        <p>Made 🔐 in India</p>
        <p>
          © {year.getFullYear()}{' '}
          <a
            href="https://www.ceegees.in"
            target="_blank"
            rel="noreferrer"
            className="w3-hover-text-blue"
          >
            {' '}
            Ceegees Pvt Ltd
          </a>
          . All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
