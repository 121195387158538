import { createContext, useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { getCurrentUser, getIsTokenValid } from '../js/redux/actions';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const initialState = {
        isAuthenticated: false,
        accessToken: null,
    };
    const dispatch = useDispatch();
    const [auth, setAuthState] = useState(initialState);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    const getUserData = () => {
        dispatch(getCurrentUser()).then(resp => {
            const respData = resp && resp.data;
            if (respData?.success && respData?.data?.user) {
                setUserData(respData?.data?.user);
            }
        });
    };

    const isTokenValid = async () => {
        const accessToken = localStorage.getItem('fab_access_token') || '';
        if (accessToken) {
            await dispatch(getIsTokenValid(accessToken)).then(resp => {
                const respData = resp && resp.data;
                if (respData?.success) {
                    setUserData(respData?.userData);
                    setAuthState({
                        accessToken,
                        isAuthenticated: true,
                    });
                } else {
                    clearData();
                }
                setLoading(false); // <- Set loading false after check
            });
        } else {
            clearData();
            setLoading(false); // <- No token, so loading is done
        }
    };

    const clearData = () => {
        setAuthState(initialState);
        setUserData(null);
        setAuthData();
    };

    const setAuthData = (token) => {
        let isAuthenticated = false;
        let accessToken = '';
        if (token) {
            accessToken = token;
            isAuthenticated = true;
        }
        localStorage.setItem('fab_access_token', accessToken);
        setAuthState({
            accessToken,
            isAuthenticated,
        });
    };

    useEffect(() => {
        if (!auth.isAuthenticated || !auth.accessToken) {
            return;
        }
        getUserData();
    }, [auth]);

    useEffect(() => {
        isTokenValid();
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuthData, userData, getUserData, isTokenValid, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
