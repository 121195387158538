import React, { useState, useCallback } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './App.scss';
import Routes from './Routes';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import ToastMessage from './components/common/ToastMessage';
import { CLIENT_ID } from './js/constants';

function App() {
  const [showDrawer, setShowDrawer] = useState(false);
  const setOrToggleDrawer = useCallback((value) => {
    setShowDrawer((prev) => (typeof value === 'boolean' ? value : !prev));
  }, []);

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <div className="fabcaller-app">
        <ToastMessage />
        <Navbar setOrToggleDrawer={setOrToggleDrawer} showDrawer={showDrawer} />
        <Routes section="auth" />
        <Footer />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
