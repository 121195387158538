import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getReporterLeaderBoard } from '../../../js/redux/actions';
import Loader from '../Loader';
import Table from '../Table';
import Dropdown from '../Dropdown';
import './leader-board.scss';

const VIEW_TYPES = [
  { key: 'all', value: 'All Time' },
  { key: 30, value: '30 days' },
  { key: 7, value: '7 days' },
  { key: 1, value: '24 Hrs' },
];

const VIEW_TYPE_MESSAGES = {
  1: 'Over the past 24 hours',
  7: 'Over the past 7 days',
  30: 'Over the past 30 days',
  all: 'So far',
};

export default function LeaderBoard() {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Get viewType from query params, default to 'all'
  const viewType = searchParams.get('viewType') || 'all';

  const getLeaderBoardData = () => {
    setIsLoading(true);
    dispatch(getReporterLeaderBoard({ viewType }))
      .then((resp) => {
        setIsLoading(false);
        const respData = resp?.data;
        if (respData?.success && respData?.data) {
          setTableData(respData.data);
        } else {
          setTableData([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setTableData([]);
        console.error('Leader board API error:', err);
      });
  };

  useEffect(() => {
    getLeaderBoardData();
  }, [viewType]);

  const headers = [
    { label: 'Name' },
    { label: 'Reports', className: 'w3-center' },
  ];

  const handleViewTypeChange = (selected) => {
    setSearchParams({ viewType: selected.key });
  };

  return (
    <div
      className="custom-margin-top fab-leader-board-container w3-round"
      id="fab-leader-board-container"
    >
      <div className="fab-leader-board-header flex-container flex-column align-items-center justify-content-space-between w3-padding-small">
        <div className="flex-container blue-color">
          <h5>Leader Board</h5>
        </div>
        <div className="fab-filter-item">
          <Dropdown
            customCls="fab-rl-drop-down"
            placeholder="Select Time Frame"
            options={VIEW_TYPES}
            selectedValue={VIEW_TYPES.find(
              (s) => s.key.toString() === viewType,
            )}
            onChange={handleViewTypeChange}
          />
        </div>
      </div>

      {isLoading ? (
        <Loader parentNode="fab-leader-board-container" />
      ) : (
        <Table
          headers={headers}
          data={tableData}
          rowKey="reporterId"
          renderRow={(item) => (
            <>
              <td className="lb-name">{item.users_name}</td>
              <td className="w3-center">{item.total}</td>
            </>
          )}
          emptyMessage={`${
            VIEW_TYPE_MESSAGES[viewType] || 'No records found'
          }, no scam reports have been reported.`}
          stickyHeader={true}
        />
      )}
    </div>
  );
}
