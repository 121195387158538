import React, { useEffect, useMemo } from 'react';
import '../../App.scss';
import './displayblogposts.scss';
import Loader from '../common/Loader';
import BlogPostCard from '../common/BlogPostCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllScamArticles } from '../../js/redux/actions';
import useFetchWithControl from '../hooks/useFetchWithControl';

function DisplayBlogPosts() {
  const dispatch = useDispatch();
  const {
    data = [],
    lastFetched = null,
    isFetching = false,
  } = useSelector((state) => ({
    data: state.allScamArticles?.data?.data,
    lastFetched: state.allScamArticles?.lastFetched,
    isFetching: state.allScamArticles?.isFetching,
  }));

  const shouldFetch = useMemo(() => {
    if (!data?.length) return true;
    return !lastFetched || Date.now() - new Date(lastFetched).getTime() > 60000;
  }, [data, lastFetched]);

  useFetchWithControl({
    fetchFn: () => dispatch(getAllScamArticles()),
    shouldFetch,
    interval: 60000,
  });

  if (isFetching) {
    return <Loader parentNode="blog-list-container" />;
  }

  return (
    <div className="blog-list-container-wrapper">
      <div className="blog-list-container" id='blog-list-container'>
        {data && data.length > 0 ? (
          data
            .slice()
            .sort((a, b) => (b.trendingScore ?? 0) - (a.trendingScore ?? 0))
            .map((item, i) => (
              <div key={i} className="blog-list-item">
                <BlogPostCard index={i} key={item.slug} item={item} />
              </div>
            ))
        ) : (
          <div className="fab-no-data w3-center">No data available</div>
        )}
      </div>
    </div>
  );
}

export default DisplayBlogPosts;
