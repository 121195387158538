import { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import TextInput from '../TextInput';
import ErrorText from '../ErrorText';
import './password-input.scss'; // Import styles

export default function PasswordInput({
  label,
  value,
  onChange,
  error,
  placeholder = 'Enter password',
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="fab-form-control">
      {label && <div className="fab-form-label">{label}</div>}
      <div className="fab-password-wrapper">
        <TextInput
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          inputclass="fab-password-input"
        />
        <button
          type="button"
          className="fab-password-toggle"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          {showPassword ? (
            <Eye color="#549aff" size={20} />
          ) : (
            <EyeOff color="#549aff" size={20} />
          )}
        </button>
      </div>
      {error && <ErrorText error={error} />}
    </div>
  );
}
