import { actions } from './actions';

const initialState = {
  toastMessage: { status: null, message: null },
  lastFetched: null,
};

const updateStateWithKey = (state, key, update) => ({
  ...state,
  [key]: {
    ...state[key],
    ...update,
  },
});

const reducer = (state = initialState, changeAction) => {
  switch (changeAction.type) {
    case actions.FETCH_REQUEST:
      return updateStateWithKey(state, changeAction.key, {
        isFetching: true,
        error: false,
      });

    case actions.FETCH_REQUEST_SUCCESS:
      return updateStateWithKey(state, changeAction.key, {
        isFetching: false,
        error: false,
        errorMessage: null,
        data: changeAction.data,
        lastFetched: new Date().toISOString(), // Adding lastFetched time
      });

    case actions.FETCH_REQUEST_ERROR:
      return updateStateWithKey(state, changeAction.key, {
        isFetching: false,
        error: true,
        errorMessage: changeAction.error,
      });

    case actions.SET_DATA:
      return updateStateWithKey(state, changeAction.key, {
        value: changeAction.value,
      });

    case actions.SET_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: {
          status: changeAction.value.status,
          message: changeAction.value.message,
        },
      };

    case actions.CLEAR_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: { status: null, message: null },
      };

    case actions.USER_LOGOUT:
      return initialState; // Reset to initial state

    default:
      return state;
  }
};

export default reducer;
