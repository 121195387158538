import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import {
  acceptOnlyNumbersInput,
  clearFormFieldErrors,
  isValidPhoneNumber,
} from '../../js/utils';
import Button from '../common/Button';
import ErrorText from '../common/ErrorText';
import PhoneNumberInput from '../common/PhoneNumberInput';
import TextInput from '../common/TextInput';
import { postGoogleLogin, postLogin, setToastMessage } from '../../js/redux/actions';
import useAuth from '../../Routes/useAuth';
import Loader from '../common/Loader';
import PasswordInput from '../common/PasswordInput';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setAuthData,
    auth: { isAuthenticated },
  } = useAuth();
  const spamData = {
    type: location?.state?.type,
    uniqueId: location?.state?.uniqueId,
  };

  const initialFormData = {
    countryCode: '+91',
    phoneNo: '',
    password: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      setFormData(initialFormData);
      setErrors({});
      setBtnDisable(false);
      setIsLoading(false);
    };
  }, []);

  const handleGoogleLoginSuccess = (credentialResponse) => {

    // Authenticate with your backend
    dispatch(postGoogleLogin({ googleToken: credentialResponse.credential })).then(
      (resp) => {
        if (resp?.data?.success) {
          dispatch(
            setToastMessage({
              status: 'success',
              message: resp?.data?.message,
            }),
          );
          setAuthData(resp?.data?.access_token);
          navigate('/home');
        } else {
          dispatch(
            setToastMessage({
              status: 'error',
              message: resp?.data?.message,
            }),
          );
        }
      },
    );
  };

  const handleGoogleLoginError = () => {
    dispatch(
      setToastMessage({
        status: 'error',
        message: 'Google Login Failed',
      }),
    );
  };

  const handlePhoneNoChange = (value) => {
    if (value === '' || acceptOnlyNumbersInput(value)) {
      setErrors(clearFormFieldErrors('phoneNo', errors));
      setFormData({
        ...formData,
        phoneNo: value,
      });
    }
  };

  const handleChange = (key, value) => {
    setErrors(clearFormFieldErrors(key, errors));
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const validateFormData = () => {
    let hasError = false;
    const err = Object.assign({}, errors);
    if (!isValidPhoneNumber(formData.phoneNo)) {
      hasError = true;
      err.phoneNo = 'Enter a valid phone number';
    }
    Object.keys(formData).forEach((key) => {
      if (
        typeof formData[key] === 'string' &&
        key !== 'password' &&
        key !== 'phoneNo' &&
        key !== 'countryCode'
      ) {
        if (!formData[key].match(/\w/)) {
          hasError = true;
          err[key] = 'This field is required';
        }
      }
      if (!formData[key]) {
        hasError = true;
        err[key] = 'This field is required';
      }
    });
    // Password strength check
    if (formData.password) {
      // const passwordStrength =
      //   /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{6,}$/;
      // if (!formData.password.match(passwordStrength)) {
      //   hasError = true;
      //   err.password =
      //     'Password must be at least 6 characters long and include at least one letter (A-Z or a-z), one number (0-9), and at least one special character (e.g., @$!%?&).';
      // }
    } else {
      hasError = true;
      err.password = 'Password is required';
    }
    if (hasError) {
      setErrors(err);
      return false;
    }
    return formData;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const isValid = validateFormData();
    if (isValid) {
      setBtnDisable(true);
      setIsLoading(true);
      dispatch(postLogin(isValid)).then((resp) => {
        setIsLoading(false);
        if (resp?.data?.success) {
          dispatch(
            setToastMessage({
              status: 'success',
              message: resp?.data?.message,
            }),
          );
          setAuthData(resp?.data?.access_token);
          if (spamData.uniqueId) {
            navigate(`/report-scam`, {
              state: {
                type: spamData.type,
                uniqueId: spamData.uniqueId,
              },
            });
          } else {
            navigate(`/home`);
          }
        } else {
          setTimeout(() => {
            setBtnDisable(false);
          }, 3500);
          dispatch(
            setToastMessage({
              status: 'error',
              message: resp?.data?.message,
            }),
          );
        }
      });
    }
  };

  const handleRegister = () => {
    navigate(`/auth/register`, {
      state: {
        type: spamData.type,
        uniqueId: spamData.uniqueId,
      },
    });
  };

  if (isAuthenticated && location?.state?.from) {
    let pathTo = location?.state?.from?.pathname || '/home';
    if (location?.state?.from?.search) {
      pathTo = `${location?.state?.from?.pathname}${location?.state?.from?.search}`;
    }
    const pathState = location?.state?.from?.state || {};
    navigate(pathTo, { state: pathState });
    return null;
  }

  return (
    <div className="fab-login-container" id="fab-login-container">
      {isLoading && <Loader parentNode="fab-login-container" />}
      <div className="fab-ac-top-text">Login</div>
      <form onSubmit={(e) => handleLogin(e)}>
        <div className="fab-form-control">
          <div className="fab-form-label">Enter Phone Number</div>
          <PhoneNumberInput
            placeholder="01234 56789"
            value={formData.phoneNo}
            name="phoneNo"
            onChange={(e) => handlePhoneNoChange(e.target.value)}
          />
          <ErrorText error={errors.phoneNo} errorClass="w3-center" />
        </div>
        <PasswordInput
          label="Enter Password"
          value={formData.password}
          onChange={(val) => handleChange('password', val)}
          error={errors.password}
        />
        <div className="fab-login-btn-section">
          <Link
            className="fab-nav-link primary-link fab-forgot-btn"
            to="/auth/forgot-password"
          >
            Forgot Password?
          </Link>
          <Button
            type="submit"
            onClick={(e) => handleLogin(e)}
            buttontext="Login"
            disabled={btnDisable}
            customclass={`primary-btn ${btnDisable ? 'fab-btn-disabled' : ''}`}
          />
        </div>
        {/* <div className="fab-login-btn-section">
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginError}
          />
        </div> */}
      </form>
      <div className="fab-login-info-text">
        <div>
          Don’t have an Account? &nbsp;
          <span
            className="fab-nav-link primary-link"
            style={{ textDecoration: 'underline' }}
            onClick={(e) => handleRegister(e)}
          >
            Register
          </span>
        </div>
      </div>
    </div>
  );
}
