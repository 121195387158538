import React, { useEffect, useState } from 'react';
import './report-list.scss';
import { getScammerList } from '../../js/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TextInput from '../common/TextInput';
import { removeNonValueFilters, formattedDate } from '../../js/utils';
import {
  SELECT_STATUS_OPTIONS,
  SELECT_TYPE_OPTIONS,
  SELECT_REPORT_OPTIONS,
} from '../../js/constants';
import Dropdown from '../common/Dropdown';
import moment from 'moment';
import Loader from '../common/Loader';
import searchIcon from './../../images/icons/search-icon.svg';
import useAuth from '../../Routes/useAuth';
import { useDebounce } from 'use-debounce';
import ReportListItem from './ReportListItem';
import Pagination from '../common/Pagination';
import { Filter } from 'lucide-react';
import Table from '../common/Table';
import EllipsisText from '../common/EllipsisText';

const ALL_OPTION = { key: '', value: 'All' };

const HEADERS = [
  { label: 'Scammer Entity' },
  { label: 'Type' },
  { label: 'Last Reported At' },
  { label: 'Report Count', className: 'w3-center' },
  { label: 'Status', className: 'w3-center' },
  { label: ' ', className: '' },
];

const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const ReportList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [results, setResults] = useState(0);
  const [queryParams, setQueryParams] = useSearchParams({});
  const existingParams = Object.fromEntries([...queryParams]);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [debouncedQueryParams] = useDebounce(queryParams, 300);
  const size = Number(queryParams.get('size')) || 15;
  const page = Number(queryParams.get('page')) || 1;
  const fromDate = queryParams.get('fromDate') || '';
  const toDate = queryParams.get('toDate') || '';
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1080);

  const {
    auth: { isAuthenticated },
  } = useAuth();

  useEffect(() => {
    getReports();
  }, [debouncedQueryParams]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1080);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobileView) {
      updateQueryParams({ size: 10 });
    } else {
      updateQueryParams({ size: 15 });
    }
  }, [isMobileView]);

  const getReports = () => {
    setIsLoading(true);
    setReports([]);
    setResults(0);

    dispatch(
      getScammerList({
        ...existingParams,
        page,
        size,
      }),
    ).then((resp) => {
      setIsLoading(false);
      const respData = resp?.data;
      if (respData?.success && respData?.data?.length > 0) {
        setResults(respData.total);
        setReports(
          respData.data.map((ele) => ({
            ...ele,
            lastReportedAt: moment(ele.lastReportedAt).format('LLL'),
          })),
        );
      }
    });
  };

  const updateQueryParams = (params) => {
    const nParams = removeNonValueFilters({
      ...existingParams,
      ...params,
      page:
        params.page !== undefined ? params.page : existingParams.page || page,
    });
    setQueryParams(nParams);
  };

  return (
    <div className="fab-report-list-container">
      <div className="fab-report-list-headers">
        {/* Header and Search */}
        <div className="fab-scam-top-section">
          <div className="fab-page-heading blue-color">
            {existingParams?.reportType === 'recent'
              ? 'Recently Reported'
              : existingParams?.reportType === 'most'
              ? 'Most Reported'
              : existingParams?.reportType === 'myreport'
              ? 'My Reports'
              : 'All Reports'}
          </div>
          <div className="fab-top-actions">
            <div className="fab-search-container">
              <TextInput
                inputclass="fab-search-box"
                placeholder="Search Report"
                value={existingParams.search || ''}
                onChange={(val) => updateQueryParams({ search: val })}
              />
              <span className="fab-search-icon fab-cursor-pointer">
                <img src={searchIcon} alt="search" />
              </span>
            </div>
            <button
              className={`fab-filter-toggle-btn fab-cursor-pointer $`}
              onClick={() => setShowFilters(!showFilters)}
              aria-label="Toggle Filters"
            >
              <Filter
                fill={showFilters ? '#549aff' : 'white'}
                strokeWidth={showFilters ? 0 : 1}
                color="#549aff"
                size={20}
              />
            </button>
          </div>
        </div>

        {/* Filters */}
        {showFilters && (
          <div className="fab-filter-section">
            <div className="fab-filter-item">
              <label className="fab-rl-filter-label">Type</label>
              <Dropdown
                customCls="fab-rl-drop-down"
                placeholder="Type"
                options={[ALL_OPTION, ...SELECT_TYPE_OPTIONS]}
                selectedValue={
                  [ALL_OPTION, ...SELECT_TYPE_OPTIONS].find(
                    (s) => s.key === existingParams.type,
                  ) || ALL_OPTION
                }
                onChange={(val) =>
                  updateQueryParams({ type: val.key, page: 1 })
                }
              />
            </div>
            <div className="fab-filter-item">
              <label className="fab-rl-filter-label">Status</label>
              <Dropdown
                customCls="fab-rl-drop-down"
                placeholder="Status"
                options={[ALL_OPTION, ...SELECT_STATUS_OPTIONS]}
                selectedValue={
                  [ALL_OPTION, ...SELECT_STATUS_OPTIONS].find(
                    (s) => s.key === existingParams.status,
                  ) || ALL_OPTION
                }
                onChange={(val) =>
                  updateQueryParams({ status: val.key, page: 1 })
                }
              />
            </div>
            <div className="fab-filter-item">
              <label className="fab-rl-filter-label">From Date</label>
              <input
                type="date"
                value={fromDate}
                max={toDate || undefined}
                onChange={(e) =>
                  updateQueryParams({ fromDate: e.target.value, page: 1 })
                }
                className="fab-date-picker"
              />
            </div>
            <div className="fab-filter-item">
              <label className="fab-rl-filter-label">To Date</label>
              <input
                type="date"
                value={toDate}
                min={fromDate || undefined}
                onChange={(e) =>
                  updateQueryParams({ toDate: e.target.value, page: 1 })
                }
                className="fab-date-picker"
              />
            </div>
            <div className="fab-filter-item">
              <label className="fab-rl-filter-label">Popular</label>
              <Dropdown
                customCls="fab-rl-drop-down"
                placeholder="Status"
                options={(() => {
                  const options = [...SELECT_REPORT_OPTIONS];
                  if (isAuthenticated)
                    options.push({ key: 'myreport', value: 'My Report' });
                  return options;
                })()}
                selectedValue={(() => {
                  const options = [...SELECT_REPORT_OPTIONS];
                  if (isAuthenticated)
                    options.push({ key: 'myreport', value: 'My Report' });
                  return (
                    options.find((s) => s.key === existingParams.reportType) ||
                    ALL_OPTION
                  );
                })()}
                onChange={(val) =>
                  updateQueryParams({ reportType: val.key, page: 1 })
                }
              />
            </div>
          </div>
        )}
      </div>
      {/* Table/List */}
      <div
        className="fab-report-detail-container"
        id="fab-report-list-container"
      >
        {isLoading ? (
          <Loader parentNode="fab-report-list-container" />
        ) : (
          <Table
            headers={HEADERS}
            data={reports}
            rowKey="id"
            renderRow={(item) => (
              <>
                <td className="lb-name">
                  <div
                    onClick={() =>
                      navigate(`/reports/detail/${item.id}`, {
                        state: { report: item },
                      })
                    }
                  >
                    <EllipsisText text={item.scammerId} size={60} />
                  </div>
                </td>
                <td className="lb-name">
                  {SELECT_TYPE_OPTIONS.find((s) => s.key === item.type)
                    ?.value || 'N/A'}
                </td>
                <td className="lb-name">
                  {formattedDate(item.lastReportedAt)}
                </td>
                <td className="w3-center lb-name">{item.reportCount}</td>
                <td className="w3-center lb-name">
                  <div
                    className={`fab-report-detail-card-status ${
                      {
                        ACTIVE: 'active',
                        REJECTED: 'rejected',
                      }[item.status] || ''
                    }`}
                  >
                    {item.status}
                  </div>
                </td>
                <td className="w3-center lb-name">
                  <div
                    onClick={() =>
                      navigate(`/reports/detail/${item.id}`, {
                        state: { report: item },
                      })
                    }
                  >
                    View
                  </div>
                </td>
              </>
            )}
            emptyMessage={`No reports found.`}
            stickyHeader={true}
            useAlternativeView={isMobileView}
            alternativeComponent={(item) => (
              <ReportListItem key={item.id} report={item} />
            )}
          />
        )}
      </div>

      {/* Pagination */}
      <Pagination
        page={page}
        size={size}
        total={results}
        onPageChange={(newPage) => updateQueryParams({ page: newPage })}
        onPageSizeChange={(newSize) =>
          updateQueryParams({ size: newSize, page: 1 })
        }
        pageSizeOptions={isMobileView ? [5, 10, 15] : [15, 20, 25]}
      />
    </div>
  );
};

export default ReportList;
