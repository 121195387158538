export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;

export const SOCIAL_LINKS = {
    twitter: 'https://twitter.com/ceegees_inc',
    facebook: 'https://www.facebook.com/groups/yodhanet',
    linkedIn: 'https://www.linkedin.com/company/ceegees-soft/mycompany/',
    discordChannel: 'https://discord.gg/W7ADGEng',
    playStore:'https://play.google.com/store/apps/details?id=com.scamyodha.app'
};

export const CONTACT_MAIL = `mail@ceegees.in`;

export const carouselData = [
    {
        shortMsg: 'Simple Awesome!!',
        bigMsg: 'So amazing that, i can get rid of spammer without worrying to share my contacts to someone (you know who i meant right 😉😜)'
    },
    {
        shortMsg: 'Simple Awesome!! 2',
        bigMsg: 'So amazing that, i can get rid of spammer without worrying to share my contacts to someone (you know who i meant right 😉😜)'
    },
    {
        shortMsg: 'Simple Awesome!! 3',
        bigMsg: 'So amazing that, i can get rid of spammer without worrying to share my contacts to someone (you know who i meant right 😉😜)'
    },
]

export const FAQS = [
    {
        title: `What is scamyodha?`,
        content: `scamyodha is a scam/spam reporting and detection application that is crowdsourced. The application is divided into two sections.<br/><br/>
        i) Reporting scam/spam - every user who has a proof detection when an incoming call comes alternatively, you may examine a link or an UPI id for malicious intent.<br/><br/>
        ii) The objective is to jointly assist the vulnerable and less informed of these threats in order to prevent financial or other losses.<br/>`
    },

    {
        title: 'How to report a spammer?',
        content: `You may report a scammer using the web or mobile application. You can provide their mobile number, UPI id, or the scam link on SMS/Email as part of the reporting procedure.
        Once the information you submitted has been confirmed, it will be made public.`
    },
    {
        title: 'How can I trust scamyodha?',
        content: `The majority of the reporting includes documentation, and verification is a two-step procedure where a trusted validator verifies the proof and provided information before making it available to the wider public.`
    },
    {
        title: 'Why should you use scamyodha?',
        content: `i) For its basic functions, scamyodha does not require access to your contact list. As a result, your contact information is not shared with any third party.<br/><br/>
        ii) It is crowdsourced information. The more users who utilize the service, the more data it will have and the faster it will be able to offer new information.<br/><br/>
        iii) It is capable of handling all types of frauds and spam whether it a phone call, SMS/Email, or UPI, and we may add support for additional types in the future.<br/><br/>
        iv) The verification procedure. The material is not posted simply because someone reports it as a hoax or spam. Before listing the information on the website, it is validated.<br/>`
    }
];

export const HOW_IT_WORKS = [
    {
        title: 'Step 1',
        content: 'To begin, enter the number you wish to verify in the box provided and pick the country of the contact.'
    },
    {
        title: 'Step 2',
        content: "Now, just below the number box, click on the 'Verify Number' button, or click on the 'Verify UPI' option to verify the number's UPI."
    },
    {
        title: 'Step 3',
        content: "By the time scamyodha confirms the number you entered, it's time to take a back seat and relax. It will be clear shortly after verification if the number is SPAM or NOT."
    },
    {
        title: 'Step 4',
        content: "Finally, if the number is found to be SPAM, you will be given the choice to report it and block it."
    },
];

export const TEAMS = [
    {
        id: 1,
        name: 'Puvikaran Selvakulasingam',
        designation: 'Developer'
    },
    {
        id: 2,
        name: 'Vismay S Raj',
        designation: 'Developer'
    },
    {
        id: 3,
        name: 'Sreya P V',
        designation: 'Developer'
    },
    {
        id: 4,
        name: 'Vipin Krishna',
        designation: 'UX Designer'
    },
];

export const TANDC = [
    {
        title: '1. 	Introduction',
        content: 'These terms and conditions govern your use of our website; by doing so, you agree to be bound by them in their entirety. You must not use our website if you disagree with these terms and conditions or any part of these terms and conditions. Cookies are used on this website. These are little pieces of information that your browser saves to help us identify you on our website. You accept our use of cookies in line with the provisions of our privacy policy by using our website and agreeing to these terms and conditions.'
    },
    {
        title: '2. 	License to our website',
        content: "We or our licensors own the intellectual property rights to the programming and design on the website, unless otherwise noted. All intellectual property rights are reserved, subject to the terms of the license below. You may view or download content of the website for caching purposes only, and print pages from the website for your own personal use, subject to the limits set forth below and elsewhere in these terms and conditions. You may not: (a) republish pages from this website (including republication on another website); (b) sell, rent, or sub-license entire pages from the website; (c) reproduce, duplicate, copy, or otherwise exploit material on our website for commercial purposes; (d) edit or otherwise modify any design on the website. or (e) redistribute material from this website unless it has been expressly made available for redistribution."
    },
    {
        title: '3. Acceptable use',
        content: "You must not use our website in any manner that harms or threatens the website's availability or accessibility, or in any way that is unlawful, illegal, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or conduct. You may not copy, store, host, transmit, send, use, publish, or distribute any content that contains (or is connected to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit, SQL injection, or other harmful computer software or practices on our website. Without our prior written consent, you may not undertake any systematic or automated data collecting activities (including, but not limited to, scraping, data mining, data extraction, and data harvesting) on or in relation to our website. You may not use our website to send or broadcast unsolicited commercial messages. Without our prior written agreement, you may not use our website for any marketing reasons."
    },
    {
        title: '4. 	Restricted Access',
        content: "Certain portions of our website are password-protected. You will be given a user ID and password when you sign up that will allow you to access restricted parts of our website or other information or services; you must keep that user ID and password secure. If you violate any of the criteria in this terms and conditions page, we may disable your user ID and password at our discretion."
    },
    {
        title: '5. 	User Granted Content',
        content: '“Your user content,” as used in these terms and conditions, refers to any material (including, but not limited to, text, photographs, audio material, video material, and audio-visual material) that you contribute to our website for any reason. YOU control the rights to YOUR data, and we guarantee to keep it private inside the scamyodha Service and not to use it for anything other than aggregated services to improve the user experience. Your user material must not be illegal or unlawful, must not infringe on the legal rights of any third party, and must not be capable of bringing legal action against you, us, or a third party (in each case under any applicable law). You must not post to the website any user material that is or has ever been the subject of any threatened or actual legal action or other similar complaint. Any material contributed to our website, saved on our systems, hosted, or published on our website is subject to editing or removal. Regardless of our rights regarding user material under these terms and conditions, we do not promise to monitor the submission of such content to, or the publishing of such content on, our website. For further privacy, we encrypt important portions of your content in our database and employ point-to-point encryption when delivering data to your browser. All the information you save on our systems is kept safe.'
    },
    {
        title: '6. 	Limitations and exclusions of liability',
        content: "Nothing in these terms and conditions will limit or include: (a) your liability for death or personal injury caused by negligence; (b) your liability for fraud or fraudulent misrepresentation; (c) your liabilities in any way that is not permitted by applicable law; or (d) include any of our or your liabilities that is not permitted by applicable law. The limitations and exceptions of liability set out in this Section and elsewhere in these terms and conditions: (a) are subject to the preceding paragraph; and (b) apply to any obligations arising under or in connection with the subject matter of these terms and conditions. Contract and tort liability (including negligence), and for violation of statutory duty are all covered. We shall not be liable for any loss or damage of any kind to the extent that the website and the information and services on the website are given free of charge. We shall not be liable to you for any damages caused by events or circumstances beyond our reasonable control. We shall not be liable to you for any business losses, such as lost or damaged profits, income, revenue, use, production, projected savings, business, contracts, commercial opportunities, or goodwill. We shall not be accountable to you if any data, database, or software is lost or corrupted."
    },
    {
        title: '7. 	Limited Warrant',
        content: "We make no guarantees about the accuracy or completeness of the information on this website, nor do we promise that it will remain available or that the information on it will be kept up to date. To the fullest extent permissible by law, we disclaim any representations, warranties, and conditions pertaining to this website and its use (including, without limitation, any implied warranties of satisfactory quality, suitability for a particular purpose, and/or the use of reasonable care and skill)."
    },
    {
        title: '8. 	Indemnity',
        content: "You hereby indemnify us and agree to keep us indemnified against any losses, damages, costs, liabilities, and expenses (including, without limitation, legal expenses and any amounts paid by you to a third party in settlement of a claim or dispute on the advice of your legal advisers) incurred or suffered by us as a result of your breach of any provision of these terms and conditions, or any claim that you have breached any provision of these terms and conditions."
    },
    {
        title: '9. 	Breaches of these Terms and Conditions',
        content: 'If you breach these terms and conditions in any way, we may take whatever action we deem appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website, and/or bringing court action against you.'
    },
    {
        title: '10.  Assignment',
        content: "Without alerting you or getting your approval, we may transfer, subcontract, or otherwise deal with our rights and/or responsibilities under these terms and conditions. Under these terms and conditions, you may not transfer, subcontract, or otherwise deal with your rights and/or obligations."
    },
    {
        title: '11.  Variation',
        content: "We have the right to change these terms and conditions at any moment. From the date of publication of the updated terms and conditions on our website, the use of our website will be governed by them. Please revisit this page on a frequent basis to ensure that you are up to date."
    },
    {
        title: '12.  Exclusion of Third-Party Rights',
        content: "These terms and conditions are just for your and our benefit, and they are not intended to benefit or be enforceable by any other party. Any third-party consent is not required for us or you to exercise our rights under these terms and conditions."
    },
    {
        title: '13.  Maintenance and Support',
        content: "Maintenance of the Company's Internet equipment will be required from time to time and as The Company sees necessary. Wherever practicable, the Company will carry out this maintenance with the least amount of inconvenience to system users."
    },
    {
        title: '14.  Disputes',
        content: "The contract's appropriate law shall be Indian English Law, and the parties agree to submit to the jurisdiction of the Indian English Courts in all matters arising from the contract. Should any disagreement arise under this contract, the Customer agrees not to withhold any payments due or refuse to make prompt payment until the decision of such dispute, setoff, or counterclaim in respect of such dispute."
    },
    {
        title: '15.  Force Majeure',
        content: "Neither party shall be responsible to the other if it fails to execute its responsibilities and obligations to the other in whole or in part due to an act of God, a government or state act of war, civil disturbance, insurrection, embargo, strike, lockout, or any other cause beyond its control. The Customer acknowledges that the scamyodha service is dependent on the service supplied by its Internet Service Provider and that such service is beyond the Company's control. The Company will utilize a well-known and well-established Internet Service Provider but will not be held accountable for the Internet Service Provider's inability to deliver the needed service for any reason."
    },
    {
        title: '16.  Entire Agreement',
        content: "These terms and conditions represent the complete agreement between you and us about your use of our website, and they replace any previous agreements in this regard."
    },
];

export const SELECT_TYPE_OPTIONS = [
    { key: 'number', value: 'Phone' },
    { key: 'upi', value: 'UPI' },
    { key: 'sms', value: 'SMS' },
    { key: 'email', value: 'Email' },
    { key: 'website', value: 'Website' },
];

export const BOX_INPUT_CONSTANTS = {
    SET_VALUE: 'SET_VALUE',
    UPDATE_VALUE: 'UPDATE_VALUE',
    SET_ALL_VALUES: 'SET_ALL_VALUES',
    MOVE_LEFT: 'moveleft',
    MOVE_RIGHT: 'move_right',
};


export const HOLA = `Our leadership team is composed of passionate leaders with a goal to modernize voice through trust, identification, and intelligence. We safeguard people from spam and fraud phone calls, and support carriers in securing their networks for everyone. That is how we came up with scamyodha.`
export const CGS_TEXT = `At Ceegees, we are passionate about creating a culture where employees love to work, feel supported and energized, and are empowered to create, innovate and challenge the status quo.`

export const SELECT_STATUS_OPTIONS = [
    { key: 'ACTIVE', value: 'Active' },
    { key: 'PENDING', value: 'Pending' },
    { key: 'REJECTED', value: 'Rejected' },
];

export const SELECT_REPORT_OPTIONS = [
    { key: 'all', value: 'All' },
    { key: 'recent', value: 'Recent' },
    { key: 'most', value: 'Most' },
];