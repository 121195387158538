import React, { useCallback, useMemo } from 'react';
import {
  ChevronsLeft,
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
} from 'lucide-react';
import './pagination.scss';

const Pagination = ({
  page = 1,
  size = 10,
  total = 0,
  onPageChange = () => {},
  onPageSizeChange = () => {},
  pageSizeOptions = [10, 15, 20],
  className = '',
  scrollToTop = true, // New flag to enable/disable scroll to top
}) => {
  const totalPages = useMemo(
    () => Math.max(1, Math.ceil(total / size)),
    [total, size],
  );

  const goToPage = useCallback(
    (newPage) => {
      const safePage = Math.max(1, Math.min(newPage, totalPages));
      if (safePage !== page) {
        onPageChange(safePage);
      }
      if (scrollToTop) {
        window.scrollTo({ top: 0, behavior: 'auto' });
      }
    },
    [page, totalPages, onPageChange, scrollToTop],
  );

  const createPageNumbers = useCallback(() => {
    const delta = 1;
    const pages = [];
    for (
      let i = Math.max(1, page - delta);
      i <= Math.min(totalPages, page + delta);
      i++
    ) {
      pages.push(i);
    }
    return pages;
  }, [page, totalPages]);

  if (totalPages <= 1) return null;

  return (
    <div
      className={`fab-pagination-container ${className}`}
      id="fab-pagination-container"
    >
      <span className="fab-pagination-results">Total {total} results</span>

      <div className="fab-pagination-controls">
        <button onClick={() => goToPage(1)} disabled={page === 1} title="First">
          <ChevronsLeft size={16} />
        </button>
        <button
          onClick={() => goToPage(page - 1)}
          disabled={page === 1}
          title="Previous"
        >
          <ChevronLeft size={16} />
        </button>

        {page > 3 && <span className="fab-pagination-ellipsis">...</span>}

        {createPageNumbers().map((pg) => (
          <button
            key={pg}
            onClick={() => goToPage(pg)}
            className={pg === page ? 'active' : ''}
          >
            {pg}
          </button>
        ))}

        {page < totalPages - 2 && (
          <span className="fab-pagination-ellipsis">...</span>
        )}

        <button
          onClick={() => goToPage(page + 1)}
          disabled={page === totalPages}
          title="Next"
        >
          <ChevronRight size={16} />
        </button>
        <button
          onClick={() => goToPage(totalPages)}
          disabled={page === totalPages}
          title="Last"
        >
          <ChevronsRight size={16} />
        </button>
      </div>

      <select
        className="fab-page-size-selector"
        value={size}
        onChange={(e) => onPageSizeChange(Number(e.target.value))}
      >
        {pageSizeOptions.map((option) => (
          <option key={option} value={option}>
            {option} per page
          </option>
        ))}
      </select>
    </div>
  );
};

export default React.memo(Pagination);
