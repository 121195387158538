import React from 'react';
import { useNavigate } from 'react-router-dom';
import rightArrow from '../../images/icons/right_arrow.svg';
import EllipsisText from '../common/EllipsisText';
import { SELECT_TYPE_OPTIONS } from '../../js/constants';

const ReportListItem = ({ report }) => {
  const navigate = useNavigate();

  return (
    <div
      className="fab-report-list-card fab-cursor-pointer"
      onClick={() =>
        navigate(`/reports/detail/${report.id}`, { state: { report } })
      }
    >
      <div className="fab-report-detail-list-card">
        <div className="fab-report-detail-card-inner">
          <div className="w3-block">
            <div className="fab-report-detail-card-title">
              <EllipsisText text={report.scammerId} size={36} />
              <div
                className={`fab-report-detail-card-status ${
                  {
                    ACTIVE: 'active',
                    REJECTED: 'rejected',
                  }[report.status] || ''
                }`}
              >
                {report.status}
              </div>
            </div>
            <div className="fab-report-detail-card-sub-desc display-flex">
              <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                Type
              </span>
              {SELECT_TYPE_OPTIONS.find((s) => s.key === report.type)?.value ||
                'N/A'}
            </div>
            <div className="fab-report-detail-card-sub-desc display-flex">
              <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                Last reported at
              </span>
              {report.lastReportedAt}
            </div>
            <div className="fab-report-detail-card-sub-desc display-flex">
              <span className="fab-report-detail-dc-sub-title custom-min-width-106">
                Report count
              </span>
              {report.reportCount}
              <span
                className="fab-cursor-pointer w3-right"
                style={{ position: 'absolute', right: '10px' }}
              >
                <img src={rightArrow} alt="view-button" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportListItem;
