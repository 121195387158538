import React from 'react';
import PropTypes from 'prop-types';
import './table.scss';

export default function Table({
  headers,
  data,
  rowKey,
  renderRow,
  className = '',
  emptyMessage = 'No data found.',
  onRowClick,
  stickyHeader = false,
  useAlternativeView = false,
  alternativeComponent,
}) {
  if (useAlternativeView && alternativeComponent) {
    return (
      <div className="alternative-view-container">
        {data && data.length > 0 ? (
          data.map((item) => (
            <React.Fragment key={item[rowKey] ?? item.id}>
              {alternativeComponent(item)}
            </React.Fragment>
          ))
        ) : (
          <div className="w3-padding-32 w3-center">
            <h5>{emptyMessage}</h5>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={`fab-table-container ${className}`}
      id="fab-table-container"
    >
      {data && data.length > 0 ? (
        <div className="w3-responsive">
          <table
            className={`w3-table-all ${
              stickyHeader ? 'fab-sticky-header' : ''
            }`}
          >
            {headers ? (
              <thead>
                <tr className="fab-lb-tr">
                  {headers.map(({ label, key, className }, idx) => (
                    <th key={idx} className={className || ''}>
                      {label}
                    </th>
                  ))}
                </tr>
              </thead>
            ) : null}
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={item[rowKey] ?? index}
                  className="fab-lb-tr"
                  onClick={() => onRowClick?.(item)}
                  style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                >
                  {renderRow(item, index)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="w3-padding-32 w3-center">
          <h5>{emptyMessage}</h5>
        </div>
      )}
    </div>
  );
}

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
    }),
  ).isRequired,
  data: PropTypes.array.isRequired,
  rowKey: PropTypes.string,
  renderRow: PropTypes.func.isRequired,
  className: PropTypes.string,
  emptyMessage: PropTypes.string,
  onRowClick: PropTypes.func,
  stickyHeader: PropTypes.bool,
  useAlternativeView: PropTypes.bool,
  alternativeComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.elementType,
  ]),
};
